/// /////////////////////////////////////////////////////////////////////////
///
/// CUSTOM PERMISSIONS
///
/// /////////////////////////////////////////////////////////////////////////

// User
export const MANAGE_EMPLOYEE_PERMISSIONS = 'user.employee.permissions.manage';
export const VIEW_EMPLOYEE_PERMISSIONS = 'user.employee.permissions.view';

// Facility
export const MANAGE_FACILITY_AUTO_ASSIGN_FLAG = 'facility.settings.auto_assign.manage';
export const MANAGE_FACILITY_ADDING_SHIFTS_FLAG = 'facility.settings.shifts.adding_shifts.manage';
export const MANAGE_FACILITY_ORIENTATION_SHIFTS_FLAG = 'facility.settings.orientation_shifts.manage';
export const MANAGE_FACILITY_UTILIZATION_MODE = 'facility.settings.utilization_mode.manage';
export const MANAGE_FACILITY_12_HOURS_SHIFTS = 'facility.shift_types.12hours.manage';
export const MANAGE_FACILITY_PREMIUM_RATE_MULTIPLIER = 'facility.settings.premium_rate_multiplier.manage';
export const MANAGE_FACILITY_DOCUMENTS = 'facility.documents.manage';
export const MANAGE_FACILITY_MEMBERS = 'facility.members.manage';
export const MANAGE_FACILITY_GEOFENCE = 'facility.geofence.manage';
export const MANAGE_DYNAMIC_RATING = 'facility.dynamic_rating.manage';
export const MANAGE_FACILITY_REQUIRED_DOCUMENTS = 'facility.facility_required_documents.manage';

// Onshift
export const ONSHIFT_FACILITY_ACTIVATION = 'onshift.facility.activation';
export const ONSHIFT_FACILITY_DEACTIVATION = 'onshift.facility.deactivation';

// Impersonating
export const IMPERSONATING_PROVIDER = 'impersonating.provider';
export const IMPERSONATING_CLIENT = 'impersonating.client';
export const IMPERSONATING_ONBOARDING = 'impersonating.onboarding';

/// /////////////////////////////////////////////////////////////////////////
///
/// LEVEL PERMISSIONS
///
/// /////////////////////////////////////////////////////////////////////////

export const LEVEL_1 = 'level.1';
export const LEVEL_2 = 'level.2';
export const LEVEL_3 = 'level.3';

/// /////////////////////////////////////////////////////////////////////////
///
/// MODULES PERMISSIONS
///
/// /////////////////////////////////////////////////////////////////////////

// Schedule
export const MODULE_SCHEDULE_MANAGE = 'schedule.manage';
export const MODULE_SCHEDULE_VIEW = 'schedule.view';
export const MODULE_SCHEDULE_WRITE = 'schedule.write';
export const MODULE_SCHEDULE_READ = 'schedule.read';

// Users
export const MODULE_USERS_MANAGE = 'user.manage';
export const MODULE_USERS_VIEW = 'user.view';
export const MODULE_USERS_WRITE = 'user.write';
export const MODULE_USERS_READ = 'user.read';
export const MODULE_USERS_PROVIDER_MANAGE = 'user.provider.manage';
export const MODULE_USERS_PROVIDER_VIEW = 'user.provider.view';
export const MODULE_USERS_PROVIDER_DEACTIVATE = 'user.provider.deactivate';
export const MODULE_USERS_PROVIDER_PAYMENTS_METHODS_MANAGE = 'user.provider.payments_methods.manage';
export const MODULE_USERS_ONBOARDING_PAYMENTS_METHODS_MANAGE = 'user.onboarding.payments_methods.manage';
export const MODULE_USERS_CLIENT_MANAGE = 'user.client.manage';
export const MODULE_USERS_CLIENT_VIEW = 'user.client.view';
export const MODULE_USERS_EMPLOYEE_MANAGE = 'user.employee.manage';
export const MODULE_USERS_EMPLOYEE_VIEW = 'user.employee.view';
export const MODULE_USERS_ONBOARDING_MANAGE = 'user.onboarding.manage';
export const MODULE_USERS_ONBOARDING_VIEW = 'user.onboarding.view';

// Teams
export const MODULE_TEAMS_MANAGE = 'team.manage';
export const MODULE_TEAMS_VIEW = 'team.view';
export const MODULE_TEAMS_WRITE = 'team.write';
export const MODULE_TEAMS_READ = 'team.read';

// Shifts
export const MODULE_SHIFTS_MANAGE = 'shifts.manage';
export const MODULE_SHIFTS_VIEW = 'shifts.view';
export const MODULE_SHIFTS_WRITE = 'shifts.write';
export const MODULE_SHIFTS_READ = 'shifts.read';
export const MODULE_SHIFTS_REQUESTED_SHIFTS_MANAGE = 'shifts.requested_shifts.manage';
export const MODULE_SHIFTS_REQUESTED_SHIFTS_VIEW = 'shifts.requested_shifts.view';
export const MODULE_SHIFTS_TIMESHEETS_MANAGE = 'shifts.timesheets.manage';
export const MODULE_SHIFTS_TIMESHEETS_VIEW = 'shifts.timesheets.view';
export const MODULE_SHIFTS_PROVIDER_INVOICES_MANAGE = 'shifts.provider_invoices.manage';
export const MODULE_SHIFTS_PROVIDER_INVOICES_VIEW = 'shifts.provider_invoices.view';
export const MODULE_SHIFTS_UNRESOLVED_SHIFTS_MANAGE = 'shifts.unresolved_shifts.manage';
export const MODULE_SHIFTS_UNRESOLVED_SHIFTS_VIEW = 'shifts.unresolved_shifts.view';
export const MODULE_SHIFTS_FORCE_ASSIGN = 'shifts.force_assign';

// Reports
export const MODULE_REPORTS_MANAGE = 'reports.manage';
export const MODULE_REPORTS_VIEW = 'reports.view';
export const MODULE_REPORTS_WRITE = 'reports.write';
export const MODULE_REPORTS_READ = 'reports.read';
export const MODULE_REPORTS_SHIFTS_REPORT_MANAGE = 'reports.shifts_report.manage';
export const MODULE_REPORTS_SHIFTS_REPORT_VIEW = 'reports.shifts_report.view';
export const MODULE_REPORTS_PPD_REPORT_MANAGE = 'reports.ppd_report.manage';
export const MODULE_REPORTS_PPD_REPORT_VIEW = 'reports.ppd_report.view';

// Payables
export const MODULE_PAYABLES_MANAGE = 'payables.manage';
export const MODULE_PAYABLES_VIEW = 'payables.view';
export const MODULE_PAYABLES_WRITE = 'payables.write';
export const MODULE_PAYABLES_READ = 'payables.read';
export const MODULE_PAYABLES_UNPAID_MANAGE = 'payables.unpaid.manage';
export const MODULE_PAYABLES_UNPAID_VIEW = 'payables.unpaid.view';
export const MODULE_PAYABLES_PAID_MANAGE = 'payables.paid.manage';
export const MODULE_PAYABLES_PAID_VIEW = 'payables.paid.view';
export const MODULE_PAYABLES_CREDITS_MANAGE = 'payables.credits.manage';
export const MODULE_PAYABLES_CREDITS_VIEW = 'payables.credits.view';
export const MODULE_PAYABLES_DEDUCTIONS_MANAGE = 'payables.deductions.manage';
export const MODULE_PAYABLES_DEDUCTIONS_VIEW = 'payables.deductions.view';

// Billing
export const MODULE_BILLING_MANAGE = 'billing.manage';
export const MODULE_BILLING_VIEW = 'billing.view';
export const MODULE_BILLING_WRITE = 'billing.write';
export const MODULE_BILLING_READ = 'billing.read';
export const MODULE_BILLING_INVOICES_MANAGE = 'billing.invoices.manage';
export const MODULE_BILLING_INVOICES_VIEW = 'billing.invoices.view';
export const MODULE_BILLING_CREDITS_MANAGE = 'billing.credits.manage';
export const MODULE_BILLING_CREDITS_VIEW = 'billing.credits.view';
export const MODULE_BILLING_CHARGES_MANAGE = 'billing.charges.manage';
export const MODULE_BILLING_CHARGES_VIEW = 'billing.charges.view';

// Onboarding
export const MODULE_ONBOARDING_MANAGE = 'onboarding.manage';
export const MODULE_ONBOARDING_VIEW = 'onboarding.view';
export const MODULE_ONBOARDING_WRITE = 'onboarding.write';
export const MODULE_ONBOARDING_READ = 'onboarding.read';
export const MODULE_ONBOARDING_GENERAL_LICENSING_MANAGE = 'onboarding.general_licensing.manage';
export const MODULE_ONBOARDING_GENERAL_LICENSING_VIEW = 'onboarding.general_licensing.view';
export const MODULE_ONBOARDING_NURSE_LICENSING_MANAGE = 'onboarding.nurse_licensing.manage';
export const MODULE_ONBOARDING_NURSE_LICENSING_VIEW = 'onboarding.nurse_licensing.view';
export const MODULE_ONBOARDING_DOCUMENTS_MANAGE = 'onboarding.documents.manage';
export const MODULE_ONBOARDING_DOCUMENTS_VIEW = 'onboarding.documents.view';
export const MODULE_ONBOARDING_DRUG_SCREENS_MANAGE = 'onboarding.drug_screens.manage';
export const MODULE_ONBOARDING_DRUG_SCREENS_VIEW = 'onboarding.drug_screens.view';
export const MODULE_ONBOARDING_BACKGROUND_CHECKS_MANAGE = 'onboarding.background_checks.manage';
export const MODULE_ONBOARDING_BACKGROUND_CHECKS_VIEW = 'onboarding.background_checks.view';
export const MODULE_ONBOARDING_PAYMENTS_METHODS_MANAGE = 'onboarding.payments_methods.manage';
export const MODULE_ONBOARDING_PAYMENTS_METHODS_VIEW = 'onboarding.payments_methods.view';

// Insights
export const MODULE_INSIGHTS_MANAGE = 'insights.manage';
export const MODULE_INSIGHTS_VIEW = 'insights.view';
export const MODULE_INSIGHTS_WRITE = 'insights.write';
export const MODULE_INSIGHTS_READ = 'insights.read';
export const MODULE_INSIGHTS_HOURS_MANAGE = 'insights.hours.manage';
export const MODULE_INSIGHTS_HOURS_VIEW = 'insights.hours.view';
export const MODULE_INSIGHTS_REVENUE_MANAGE = 'insights.revenue.manage';
export const MODULE_INSIGHTS_REVENUE_VIEW = 'insights.revenue.view';
export const MODULE_INSIGHTS_REGIONS_MANAGE = 'insights.regions.manage';
export const MODULE_INSIGHTS_REGIONS_VIEW = 'insights.regions.view';
export const MODULE_INSIGHTS_ONBOARDING_MANAGE = 'insights.onboarding.manage';
export const MODULE_INSIGHTS_ONBOARDING_VIEW = 'insights.onboarding.view';
export const MODULE_INSIGHTS_COMMISSION_MANAGE = 'insights.commission.manage';
export const MODULE_INSIGHTS_COMMISSION_VIEW = 'insights.commission.view';
export const MODULE_INSIGHTS_CREDIT_LIMITS_MANAGE = 'insights.credit_limits.manage';
export const MODULE_INSIGHTS_CREDIT_LIMITS_VIEW = 'insights.credit_limits.view';
export const MODULE_INSIGHTS_FACILITY_MAP_MANAGE = 'insights.facility_map.manage';
export const MODULE_INSIGHTS_FACILITY_MAP_VIEW = 'insights.facility_map.view';
export const MODULE_INSIGHTS_SCHEDULED_HOURS_MANAGE = 'insights.scheduled_hours.manage';
export const MODULE_INSIGHTS_SCHEDULED_HOURS_VIEW = 'insights.scheduled_hours.view';
export const MODULE_INSIGHTS_WORK_LOGS_MANAGE = 'insights.work_logs.manage';
export const MODULE_INSIGHTS_WORK_LOGS_VIEW = 'insights.work_logs.view';
export const MODULE_INSIGHTS_MESSAGING_MANAGE = 'insights.messaging.manage';
export const MODULE_INSIGHTS_MESSAGING_VIEW = 'insights.messaging.view';
export const MODULE_INSIGHTS_RESOLUTIONS_MANAGE = 'insights.resolutions.manage';
export const MODULE_INSIGHTS_RESOLUTIONS_VIEW = 'insights.resolutions.view';
export const MODULE_INSIGHTS_VALIDATION_MANAGE = 'insights.validation.manage';
export const MODULE_INSIGHTS_VALIDATION_VIEW = 'insights.validation.view';

// Help
export const MODULE_HELP_MANAGE = 'help.manage';
export const MODULE_HELP_VIEW = 'help.view';

// Downloads
export const MODULE_DOWNLOAD_MANAGE = 'downloads.manage';
export const MODULE_DOWNLOAD_VIEW = 'downloads.view';
export const MODULE_DOWNLOAD_WRITE = 'downloads.write';
export const MODULE_DOWNLOAD_READ = 'downloads.read';

// Messaging
export const MODULE_MESSAGING_MANAGE = 'messaging.manage';
export const MODULE_MESSAGING_VIEW = 'messaging.view';
export const MODULE_MESSAGING_WRITE = 'messaging.write';
export const MODULE_MESSAGING_READ = 'messaging.read';

// Settings
export const MODULE_SETTINGS_MANAGE = 'settings.manage';
export const MODULE_SETTINGS_VIEW = 'settings.view';
export const MODULE_SETTINGS_WRITE = 'settings.write';
export const MODULE_SETTINGS_READ = 'settings.read';
export const MODULE_SETTINGS_SPECIALTIES_MANAGE = 'settings.specialties.manage';
export const MODULE_SETTINGS_SPECIALTIES_VIEW = 'settings.specialties.view';
export const MODULE_SETTINGS_SKILLS_MANAGE = 'settings.skills.manage';
export const MODULE_SETTINGS_SKILLS_VIEW = 'settings.skills.view';
export const MODULE_SETTINGS_CREDENTIALS_MANAGE = 'settings.credentials.manage';
export const MODULE_SETTINGS_CREDENTIALS_VIEW = 'settings.credentials.view';
export const MODULE_SETTINGS_CREDENTIAL_EXPLAINER_VIEW = 'settings.credential_explainer.view';
export const MODULE_SETTINGS_REGIONS_MANAGE = 'settings.regions.manage';
export const MODULE_SETTINGS_REGIONS_VIEW = 'settings.regions.view';
export const MODULE_SETTINGS_HOLIDAYS_VIEW = 'settings.holidays.view';
export const MODULE_SETTINGS_HOLIDAYS_MANAGE = 'settings.holidays.manage';
export const MODULE_SETTINGS_MARKETERS_MANAGE = 'settings.marketers.manage';
export const MODULE_SETTINGS_MARKETERS_VIEW = 'settings.marketers.view';
export const MODULE_SETTINGS_AR_FACTORING_MANAGE = 'settings.ar_factoring.manage';
export const MODULE_SETTINGS_AR_FACTORING_VIEW = 'settings.ar_factoring.view';
export const MODULE_SETTINGS_PAYOUT_DEDUCTIONS_MANAGE = 'settings.payout_deductions.manage';
export const MODULE_SETTINGS_PAYOUT_DEDUCTIONS_VIEW = 'settings.payout_deductions.view';
export const MODULE_SETTINGS_FACILITY_FEATURE_FLAG_MANAGE = 'settings.facility_feature_flag.manage';
export const MODULE_SETTINGS_FACILITY_FEATURE_FLAG_VIEW = 'settings.facility_feature_flag.view';
export const MODULE_SETTINGS_ROLES_MANAGE = 'settings.roles.manage';
export const MODULE_SETTINGS_ROLES_VIEW = 'settings.roles.view';
export const MODULE_SETTINGS_DEMO_GENERATOR_MANAGE = 'settings.demo_generator.manage';
export const MODULE_SETTINGS_MISC_MANAGE = 'settings.misc.manage';

export const permissions = {
  /// /////////////////////////////////////////////////////////////////////////
  ///
  /// CUSTOM PERMISSIONS
  ///
  /// /////////////////////////////////////////////////////////////////////////
  // User
  [MANAGE_EMPLOYEE_PERMISSIONS]: 'Manage employee permissions',
  [VIEW_EMPLOYEE_PERMISSIONS]: 'View employee permissions',

  // Facility
  [MANAGE_FACILITY_AUTO_ASSIGN_FLAG]: 'Manage facility auto-assign',
  [MANAGE_FACILITY_ADDING_SHIFTS_FLAG]: 'Manage facility adding shifts',
  [MANAGE_FACILITY_ORIENTATION_SHIFTS_FLAG]: 'Manage facility orientation shifts',
  [MANAGE_FACILITY_UTILIZATION_MODE]: 'Manage facility utilization mode',
  [MANAGE_FACILITY_12_HOURS_SHIFTS]: 'Manage 12+ hours shifts',
  [MANAGE_FACILITY_PREMIUM_RATE_MULTIPLIER]: 'Manage premium rate multiplier',
  [MANAGE_FACILITY_DOCUMENTS]: 'Manage facility document',
  [MANAGE_FACILITY_MEMBERS]: 'Manage facility members',
  [MANAGE_FACILITY_GEOFENCE]: 'Manage facility geofence',
  [MANAGE_DYNAMIC_RATING]: 'Manage dynamic rating',
  [MANAGE_FACILITY_REQUIRED_DOCUMENTS]: 'Manage Facility Required Documents',

  // Onshift
  [ONSHIFT_FACILITY_ACTIVATION]: 'OnShift facility activation',

  // Impersonating
  [IMPERSONATING_PROVIDER]: 'Impersonating provider',
  [IMPERSONATING_CLIENT]: 'Impersonating client',
  [IMPERSONATING_ONBOARDING]: 'Impersonating onboarding',

  /// /////////////////////////////////////////////////////////////////////////
  ///
  /// MODULES PERMISSIONS
  ///
  /// /////////////////////////////////////////////////////////////////////////

  // Schedule
  [MODULE_SCHEDULE_MANAGE]: 'Manage Schedule',
  [MODULE_SCHEDULE_VIEW]: 'View Schedule',
  [MODULE_SCHEDULE_WRITE]: 'Write Schedule',
  [MODULE_SCHEDULE_READ]: 'Read Schedule',

  // Users
  [MODULE_USERS_MANAGE]: 'Manage Users',
  [MODULE_USERS_VIEW]: 'View Users',
  [MODULE_USERS_WRITE]: 'Write Users',
  [MODULE_USERS_READ]: 'Read Users',
  [MODULE_USERS_PROVIDER_MANAGE]: 'Manage Users - Providers',
  [MODULE_USERS_PROVIDER_VIEW]: 'View Users - Providers',
  [MODULE_USERS_PROVIDER_DEACTIVATE]: 'Deactivate/Reactivate - Providers',
  [MODULE_USERS_CLIENT_MANAGE]: 'Manage Users - Clients',
  [MODULE_USERS_CLIENT_VIEW]: 'View Users - Clients',
  [MODULE_USERS_EMPLOYEE_MANAGE]: 'Manage Users - Employees',
  [MODULE_USERS_EMPLOYEE_VIEW]: 'View Users - Employees',
  [MODULE_USERS_ONBOARDING_MANAGE]: 'Manage Users - On-Boarding',
  [MODULE_USERS_ONBOARDING_VIEW]: 'View Users - On-Boarding',
  [MODULE_USERS_ONBOARDING_PAYMENTS_METHODS_MANAGE]: 'Manage Users - On-Boarding Payment Method',

  // Teams
  [MODULE_TEAMS_MANAGE]: 'Manage Teams',
  [MODULE_TEAMS_VIEW]: 'View Teams',
  [MODULE_TEAMS_WRITE]: 'Write Teams',
  [MODULE_TEAMS_READ]: 'Read Teams',

  // Shifts
  [MODULE_SHIFTS_MANAGE]: 'Manage Shifts',
  [MODULE_SHIFTS_VIEW]: 'View Shifts',
  [MODULE_SHIFTS_WRITE]: 'Write Shifts',
  [MODULE_SHIFTS_READ]: 'Read Shifts',
  [MODULE_SHIFTS_REQUESTED_SHIFTS_MANAGE]: 'Manage Shifts - Requested Shifts',
  [MODULE_SHIFTS_REQUESTED_SHIFTS_VIEW]: 'View Shifts - Requested Shifts',
  [MODULE_SHIFTS_TIMESHEETS_MANAGE]: 'Manage Shifts - Timesheets',
  [MODULE_SHIFTS_TIMESHEETS_VIEW]: 'View Shifts - Timesheets',
  [MODULE_SHIFTS_PROVIDER_INVOICES_MANAGE]: 'Manage Shifts - Provider Invoices',
  [MODULE_SHIFTS_PROVIDER_INVOICES_VIEW]: 'View Shifts - Provider Invoices',
  [MODULE_SHIFTS_UNRESOLVED_SHIFTS_MANAGE]: 'Manage Shifts - Unresolved Shifts',
  [MODULE_SHIFTS_UNRESOLVED_SHIFTS_VIEW]: 'View Shifts - Unresolved Shifts',

  // Reports
  [MODULE_REPORTS_MANAGE]: 'Manage Reports',
  [MODULE_REPORTS_VIEW]: 'View Reports',
  [MODULE_REPORTS_WRITE]: 'Write Reports',
  [MODULE_REPORTS_READ]: 'Read Reports',
  [MODULE_REPORTS_SHIFTS_REPORT_MANAGE]: 'Manage Reports - Shifts Report',
  [MODULE_REPORTS_SHIFTS_REPORT_VIEW]: 'View Reports - Shifts Report',
  [MODULE_REPORTS_PPD_REPORT_MANAGE]: 'Manage Reports - PPD Report',
  [MODULE_REPORTS_PPD_REPORT_VIEW]: 'View Reports - PPD Report',

  // Payables
  [MODULE_PAYABLES_MANAGE]: 'Manage Payables',
  [MODULE_PAYABLES_VIEW]: 'View Payables',
  [MODULE_PAYABLES_WRITE]: 'Write Payables',
  [MODULE_PAYABLES_READ]: 'Read Payables',
  [MODULE_PAYABLES_UNPAID_MANAGE]: 'Manage Payables - Unpaid',
  [MODULE_PAYABLES_UNPAID_VIEW]: 'View Payables - Unpaid',
  [MODULE_PAYABLES_PAID_MANAGE]: 'Manage Payables - Paid',
  [MODULE_PAYABLES_PAID_VIEW]: 'View Payables - Paid',
  [MODULE_PAYABLES_CREDITS_MANAGE]: 'Manage Payables - Credits',
  [MODULE_PAYABLES_CREDITS_VIEW]: 'View Payables - Credits',
  [MODULE_PAYABLES_DEDUCTIONS_MANAGE]: 'Manage Payables - Deductions',
  [MODULE_PAYABLES_DEDUCTIONS_VIEW]: 'View Payables - Deductions',

  // Billing
  [MODULE_BILLING_MANAGE]: 'Manage Billing',
  [MODULE_BILLING_VIEW]: 'View Billing',
  [MODULE_BILLING_WRITE]: 'Write Billing',
  [MODULE_BILLING_READ]: 'Read Billing',
  [MODULE_BILLING_INVOICES_MANAGE]: 'Manage Billing - Invoices',
  [MODULE_BILLING_INVOICES_VIEW]: 'View Billing - Invoices',
  [MODULE_BILLING_CREDITS_MANAGE]: 'Manage Billing - Credits',
  [MODULE_BILLING_CREDITS_VIEW]: 'View Billing - Credits',
  [MODULE_BILLING_CHARGES_MANAGE]: 'Manage Billing - Charges',
  [MODULE_BILLING_CHARGES_VIEW]: 'View Billing - Charges',

  // Onboarding
  [MODULE_ONBOARDING_MANAGE]: 'Manage Onboarding',
  [MODULE_ONBOARDING_VIEW]: 'View Onboarding',
  [MODULE_ONBOARDING_WRITE]: 'Write Onboarding',
  [MODULE_ONBOARDING_READ]: 'Read Onboarding',
  [MODULE_ONBOARDING_GENERAL_LICENSING_MANAGE]: 'Manage Onboarding - General Licensing',
  [MODULE_ONBOARDING_GENERAL_LICENSING_VIEW]: 'View Onboarding - General Licensing',
  [MODULE_ONBOARDING_NURSE_LICENSING_MANAGE]: 'Manage Onboarding - Nurse Licensing',
  [MODULE_ONBOARDING_NURSE_LICENSING_VIEW]: 'View Onboarding - Nurse Licensing',
  [MODULE_ONBOARDING_DOCUMENTS_MANAGE]: 'Manage Onboarding - Documents',
  [MODULE_ONBOARDING_DOCUMENTS_VIEW]: 'View Onboarding - Documents',
  [MODULE_ONBOARDING_DRUG_SCREENS_MANAGE]: 'Manage Onboarding - Drug Screens',
  [MODULE_ONBOARDING_DRUG_SCREENS_VIEW]: 'View Onboarding - Drug Screens',
  [MODULE_ONBOARDING_BACKGROUND_CHECKS_MANAGE]: 'Manage Onboarding - Background Checks',
  [MODULE_ONBOARDING_BACKGROUND_CHECKS_VIEW]: 'View Onboarding - Background Checks',
  [MODULE_ONBOARDING_PAYMENTS_METHODS_MANAGE]: 'Manage Onboarding - Payments Methods',
  [MODULE_ONBOARDING_PAYMENTS_METHODS_VIEW]: 'View Onboarding - Payments Methods',

  // Providers
  [MODULE_USERS_PROVIDER_PAYMENTS_METHODS_MANAGE]: 'Manage Payment Methods',

  // Insights
  [MODULE_INSIGHTS_MANAGE]: 'Manage Insights',
  [MODULE_INSIGHTS_VIEW]: 'View Insights',
  [MODULE_INSIGHTS_WRITE]: 'Write Insights',
  [MODULE_INSIGHTS_READ]: 'Read Insights',
  [MODULE_INSIGHTS_HOURS_MANAGE]: 'Manage Insights - Hours',
  [MODULE_INSIGHTS_HOURS_VIEW]: 'View Insights - Hours',
  [MODULE_INSIGHTS_REVENUE_MANAGE]: 'Manage Insights - Revenue',
  [MODULE_INSIGHTS_REVENUE_VIEW]: 'View Insights - Revenue',
  [MODULE_INSIGHTS_REGIONS_MANAGE]: 'Manage Insights - Regions',
  [MODULE_INSIGHTS_REGIONS_VIEW]: 'View Insights - Regions',
  [MODULE_INSIGHTS_ONBOARDING_MANAGE]: 'Manage Insights - Onboarding',
  [MODULE_INSIGHTS_ONBOARDING_VIEW]: 'View Insights - Onboarding',
  [MODULE_INSIGHTS_COMMISSION_MANAGE]: 'Manage Insights - Commission',
  [MODULE_INSIGHTS_COMMISSION_VIEW]: 'View Insights - Commission',
  [MODULE_INSIGHTS_CREDIT_LIMITS_MANAGE]: 'Manage Insights - Credit Limits',
  [MODULE_INSIGHTS_CREDIT_LIMITS_VIEW]: 'View Insights - Credit Limits',
  [MODULE_INSIGHTS_FACILITY_MAP_MANAGE]: 'Manage Insights - Facility Map',
  [MODULE_INSIGHTS_FACILITY_MAP_VIEW]: 'View Insights - Facility Map',
  [MODULE_INSIGHTS_SCHEDULED_HOURS_MANAGE]: 'Manage Insights - Scheduled Hours',
  [MODULE_INSIGHTS_SCHEDULED_HOURS_VIEW]: 'View Insights - Scheduled Hours',
  [MODULE_INSIGHTS_WORK_LOGS_MANAGE]: 'Manage Insights - Work Logs',
  [MODULE_INSIGHTS_WORK_LOGS_VIEW]: 'View Insights - Work Logs',
  [MODULE_INSIGHTS_MESSAGING_MANAGE]: 'Manage Insights - Messaging',
  [MODULE_INSIGHTS_MESSAGING_VIEW]: 'View Insights - Messaging',
  [MODULE_INSIGHTS_RESOLUTIONS_MANAGE]: 'Manage Insights - Resolutions',
  [MODULE_INSIGHTS_RESOLUTIONS_VIEW]: 'View Insights - Resolutions',
  [MODULE_INSIGHTS_VALIDATION_MANAGE]: 'Manage Insights - Validation',
  [MODULE_INSIGHTS_VALIDATION_VIEW]: 'View Insights - Validation',

  // Help
  [MODULE_HELP_MANAGE]: 'Manage Help',
  [MODULE_HELP_VIEW]: 'View Help',

  // Downloads
  [MODULE_DOWNLOAD_MANAGE]: 'Manage Download',
  [MODULE_DOWNLOAD_VIEW]: 'View Download',
  [MODULE_DOWNLOAD_WRITE]: 'Write Download',
  [MODULE_DOWNLOAD_READ]: 'Read Download',

  // Messaging
  [MODULE_MESSAGING_MANAGE]: 'Manage Messaging',
  [MODULE_MESSAGING_VIEW]: 'View Messaging',
  [MODULE_MESSAGING_WRITE]: 'Write Messaging',
  [MODULE_MESSAGING_READ]: 'Read Messaging',

  // Settings
  [MODULE_SETTINGS_MANAGE]: 'Manage Settings',
  [MODULE_SETTINGS_VIEW]: 'View Settings',
  [MODULE_SETTINGS_WRITE]: 'Write Settings',
  [MODULE_SETTINGS_READ]: 'Read Settings',
  [MODULE_SETTINGS_SPECIALTIES_MANAGE]: 'Manage Settings - Specialties',
  [MODULE_SETTINGS_SPECIALTIES_VIEW]: 'View Settings - Specialties',
  [MODULE_SETTINGS_SKILLS_MANAGE]: 'Manage Settings - Skills',
  [MODULE_SETTINGS_SKILLS_VIEW]: 'View Settings - Skills',
  [MODULE_SETTINGS_CREDENTIALS_MANAGE]: 'Manage Settings - Credentials',
  [MODULE_SETTINGS_CREDENTIALS_VIEW]: 'View Settings - Credentials',
  [MODULE_SETTINGS_CREDENTIAL_EXPLAINER_VIEW]: 'View Settings - Credential Explainer',
  [MODULE_SETTINGS_REGIONS_MANAGE]: 'Manage Settings - Regions',
  [MODULE_SETTINGS_REGIONS_VIEW]: 'View Settings - Regions',
  [MODULE_SETTINGS_HOLIDAYS_MANAGE]: 'Manage Settings - Holidays',
  [MODULE_SETTINGS_HOLIDAYS_VIEW]: 'View Settings - Holidays',
  [MODULE_SETTINGS_MARKETERS_MANAGE]: 'Manage Settings - Marketers',
  [MODULE_SETTINGS_MARKETERS_VIEW]: 'View Settings - Marketers',
  [MODULE_SETTINGS_AR_FACTORING_MANAGE]: 'Manage Settings - AR Factoring',
  [MODULE_SETTINGS_AR_FACTORING_VIEW]: 'View Settings - AR Factoring',
  [MODULE_SETTINGS_PAYOUT_DEDUCTIONS_MANAGE]: 'Manage Settings - Payout Deductions',
  [MODULE_SETTINGS_PAYOUT_DEDUCTIONS_VIEW]: 'View Settings - Payout Deductions',
  [MODULE_SETTINGS_FACILITY_FEATURE_FLAG_MANAGE]: 'Manage Settings - Facility Feature Flag',
  [MODULE_SETTINGS_FACILITY_FEATURE_FLAG_VIEW]: 'View Settings - Facility Feature Flag',
  [MODULE_SETTINGS_ROLES_MANAGE]: 'Manage Settings - Roles',
  [MODULE_SETTINGS_ROLES_VIEW]: 'View Settings - Roles',
  [MODULE_SETTINGS_DEMO_GENERATOR_MANAGE]: 'Manage Settings - Demo Generator',
  [MODULE_SETTINGS_MISC_MANAGE]: 'Manage Settings - Misc',
};
