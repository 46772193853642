import { employeeService } from '@/services/userService/EmployeeService';

const config = [
  {
    show: employeeService.canViewSchedule(),
    action: 'far fa-calendar-alt',
    title: 'Schedule',
    url: '/schedule',
  },
  {
    show: (
      employeeService.canViewUsersProviders()
      || employeeService.canViewUsersClients()
      || employeeService.canViewUsersEmployees()
      || employeeService.canViewUsersOnboarding()
    ),
    action: 'fa-user',
    title: 'Users',
    items: [
      {
        show: employeeService.canViewUsersProviders(),
        title: 'Professionals',
        url: '/users/providers',
      },
      {
        show: employeeService.canViewUsersClients(),
        title: 'Clients',
        url: '/users/clients',
      },
      {
        show: employeeService.canViewUsersEmployees(),
        title: 'Employees',
        url: '/users/employees',
      },
      {
        show: employeeService.canViewUsersOnboarding(),
        title: 'On-Boarding',
        url: '/users/onboarding',
      },
    ],
  },
  {
    show: employeeService.canViewTeams(),
    action: 'fas fa-users',
    title: 'Teams',
    url: '/teams',
  },
  {
    show: employeeService.canViewTeams(),
    action: 'far fa-hospital',
    title: 'Facilities',
    url: '/facilities',
  },
  {
    show: (
      employeeService.canViewShifts()
      || employeeService.canViewShiftsRequestedShifts()
      || employeeService.canViewShiftsTimesheets()
      || employeeService.canViewShiftsProviderInvoices()
      || employeeService.canViewShiftsUnresolvedShifts()
    ),
    action: 'fas fa-user-clock',
    title: 'Shifts',
    items: [
      {
        show: employeeService.canViewShiftsRequestedShifts(),
        title: 'Requested Shifts',
        url: '/shifts/requested_shifts',
      },
      {
        show: employeeService.canViewShiftsTimesheets(),
        title: 'Timesheets',
        url: '/shifts/timesheets',
      },
      {
        show: employeeService.canViewShiftsProviderInvoices(),
        title: 'Professional Invoices',
        url: '/provider_digital_invoice',
      },
      {
        show: employeeService.canViewShiftsUnresolvedShifts(),
        title: 'Unresolved Shifts',
        url: '/shifts/unresolved_shifts',
      },
    ],
  },
  {
    show: (
      employeeService.canViewReports()
      || employeeService.canViewReportsShiftsReport()
      || employeeService.canViewReportsPpdReport()
    ),
    action: 'fas fa-clipboard',
    title: 'Reports',
    items: [
      {
        show: employeeService.canViewReportsShiftsReport(),
        title: 'Shifts Report',
        url: '/shifts/report',
      },
      {
        show: employeeService.canViewReportsPpdReport(),
        title: 'PPD Report',
        url: '/ppd-report',
      },
    ],
  },
  {
    show: (
      employeeService.canViewPayables()
      || employeeService.canViewPayablesUnpaid()
      || employeeService.canViewPayablesPaid()
      || employeeService.canViewPayablesCredits()
      || employeeService.canViewPayablesDeductions()
    ),
    action: 'fas fa-university',
    title: 'Payables',
    items: [
      {
        show: employeeService.canViewPayablesUnpaid(),
        title: 'Unpaid',
        url: '/payables/unpaid',
      },
      {
        show: employeeService.canViewPayablesPaid(),
        title: 'Paid',
        url: '/payables/paid',
      },
      {
        show: employeeService.canViewPayablesCredits(),
        title: 'Credits',
        url: '/payables/credits',
      },
      {
        show: employeeService.canViewPayablesDeductions(),
        title: 'Deductions',
        url: '/payables/provider_deductions',
      },
    ],
  },
  {
    show: (
      employeeService.canViewBilling()
      || employeeService.canViewBillingInvoices()
      || employeeService.canViewBillingCredits()
      || employeeService.canViewBillingCharges()
    ),
    action: 'fa fa-file-invoice',
    title: 'Billing',
    items: [
      {
        show: employeeService.canViewBillingInvoices(),
        title: 'Invoices',
        url: '/billing/invoices',
      },
      {
        show: employeeService.canViewBillingCredits(),
        title: 'Credits',
        url: '/billing/credits',
      },
      {
        show: employeeService.canViewBillingCharges(),
        title: 'Charges',
        url: '/billing/charges',
      },
    ],
  },
  {
    show: (
      employeeService.canViewOnboarding()
      || employeeService.canViewOnboardingGeneralLicensing()
      || employeeService.canViewOnboardingNurseLicensing()
      || employeeService.canViewOnboardingDocuments()
      || employeeService.canViewOnboardingDrugScreens()
      || employeeService.canViewOnboardingBackgroundChecks()
      || employeeService.canViewOnboardingPaymentsMethods()
    ),
    action: 'fa-rocket',
    title: 'On-Boarding',
    items: [
      {
        show: employeeService.canViewOnboardingGeneralLicensing(),
        title: 'General Licensing',
        url: '/onboarding/licensing',
      },
      {
        show: employeeService.canViewOnboardingNurseLicensing(),
        title: 'Nurse Licensing',
        url: '/onboarding/nurse-licensing',
      },
      {
        show: employeeService.canViewOnboardingDocuments(),
        title: 'Documents',
        url: '/onboarding/documents',
      },
      {
        show: employeeService.canViewOnboardingDrugScreens(),
        title: 'Drug Screens',
        url: '/onboarding/drug-screens',
      },
      {
        show: employeeService.canViewOnboardingBackgroundChecks(),
        title: 'Background Checks',
        url: '/onboarding/background-checks',
      },
      {
        show: employeeService.canViewOnboardingPaymentsMethods(),
        title: 'Payment Methods',
        url: '/onboarding/payment-methods',
      },
    ],
  },
  {
    show: (
      employeeService.canViewInsights()
      || employeeService.canViewInsightsHours()
      || employeeService.canViewInsightsRevenue()
      || employeeService.canViewInsightsRegions()
      || employeeService.canViewInsightsOnboarding()
      || employeeService.canViewInsightsCommission()
      || employeeService.canViewInsightsCreditLimits()
      || employeeService.canViewInsightsFacilityMap()
      || employeeService.canViewInsightsScheduledHours()
      || employeeService.canViewInsightsWorkLogs()
      || employeeService.canViewInsightsMessaging()
      || employeeService.canViewInsightsResolution()
      || employeeService.canViewInsightsValidation()
    ),
    action: 'fa-crosshairs',
    title: 'Insights',
    items: [
      {
        show: employeeService.canViewInsightsHours(),
        title: 'Hours',
        url: '/insights/hours',
      },
      {
        show: employeeService.canViewInsightsRevenue(),
        title: 'Revenue',
        url: '/insights/revenue',
      },
      {
        show: employeeService.canViewInsightsRegions(),
        title: 'Regions',
        url: '/insights/regions',
      },
      {
        show: employeeService.canViewInsightsOnboarding(),
        title: 'Onboarding',
        url: '/insights/onboarding',
      },
      {
        show: employeeService.canViewInsightsCommission(),
        title: 'Commission',
        url: '/insights/commission',
      },
      {
        show: employeeService.canViewInsightsCreditLimits(),
        title: 'Credit Limits',
        url: '/insights/credit-limits',
      },
      {
        show: employeeService.canViewInsightsFacilityMap(),
        title: 'Facility Map',
        url: '/insights/facility-map',
      },
      {
        show: employeeService.canViewInsightsScheduledHours(),
        title: 'Scheduled Hours',
        url: '/insights/s-hours',
      },
      {
        show: employeeService.canViewInsightsWorkLogs(),
        title: 'Work Logs',
        url: '/insights/work_logs',
      },
      {
        show: employeeService.canViewInsightsMessaging(),
        title: 'Messaging',
        url: '/insights/messaging',
      },
      {
        show: employeeService.canViewInsightsResolution(),
        title: 'Resolutions',
        url: '/insights/resolutions',
      },
      {
        show: employeeService.canViewInsightsValidation(),
        title: 'Validation',
        url: '/insights/credential-validation',
      },
    ],
  },
  {
    show: employeeService.canViewHelp(),
    action: 'fas fa-hands-helping',
    title: 'Help',
    items: [
      {
        show: true,
        title: 'Help Center',
        url: import.meta.env.VITE_SHIFTKEY_HELP_LINK,
        blank: true,
        useRouter: false,
      },
    ],
  },
  {
    show: employeeService.canViewDownload(),
    action: 'fas fa-cloud-download-alt',
    title: 'Downloads',
    url: '/downloads',
  },
  {
    show: employeeService.canViewMessaging(),
    action: 'fas fa-envelope',
    title: 'Messaging',
    url: '/messaging',
  },
  {
    show: (
      employeeService.canViewSettings()
      || employeeService.canViewSettingsSpecialties()
      || employeeService.canViewSettingsSkills()
      || employeeService.canViewSettingsCredentials()
      || employeeService.canViewSettingsCredentialExplainer()
      || employeeService.canViewSettingsRegions()
      || employeeService.canViewSettingsMarketers()
      || employeeService.canViewSettingsArFactoring()
      || employeeService.canViewSettingsPayoutDeductions()
      || employeeService.canViewSettingsFacilityFeatureFlags()
      || employeeService.canViewSettingsRoles()
      || employeeService.canViewSettingsHolidays()
      || employeeService.canManageSettingsDemoGenerator()
    ),
    action: 'fas fa-cogs',
    title: 'Settings',
    items: [
      {
        show: employeeService.canViewSettingsSpecialties(),
        title: 'Specialties',
        url: '/settings/specialties',
      },
      {
        show: employeeService.canViewSettingsSkills(),
        title: 'Skills',
        url: '/settings/skills',
      },
      {
        show: employeeService.canViewSettingsCredentials(),
        title: 'Credentials',
        url: '/settings/credentials/all',
      },
      {
        show: employeeService.canViewSettingsCredentials(),
        title: 'Credential History',
        url: '/settings/credential_constraints_history',
      },
      {
        show: employeeService.canViewSettingsCredentialExplainer(),
        title: 'Credential Explainer',
        url: '/settings/credential_explainer',
      },
      {
        show: employeeService.canViewSettingsRegions(),
        title: 'Regions',
        url: '/settings/regions',
      },
      {
        show: employeeService.canViewSettingsHolidays(),
        title: 'Holidays',
        url: '/settings/holidays',
      },
      {
        show: employeeService.canViewSettingsMarketers(),
        title: 'Marketers',
        url: '/settings/marketers',
      },
      {
        show: employeeService.canViewSettingsArFactoring(),
        title: 'AR Factoring',
        url: '/settings/ar_factoring',
      },
      {
        show: employeeService.canViewSettingsPayoutDeductions(),
        title: 'Payout Deductions',
        url: '/settings/payout_deductions/',
      },
      {
        show: employeeService.canViewSettingsFacilityFeatureFlags(),
        title: 'Facility Feature Flags',
        url: '/settings/facility_feature_flags/all',
      },
      {
        show: employeeService.canViewSettingsRoles(),
        title: 'Roles',
        url: '/settings/roles',
      },
      {
        show: employeeService.canManageSettingsMisc(),
        title: 'Misc',
        url: '/settings/misc/all',
      },
      {
        show: import.meta.env.VITE_APP_ENV === 'demo' && window.user?.superuser,
        title: 'Demo',
        url: '/settings/demo',
      },
      {
        show: import.meta.env.VITE_APP_ENV !== 'prod' && employeeService.canManageSettingsDemoGenerator(),
        title: 'Demo Generator',
        url: '/settings/prepare-demo',
      },
    ],
  },
  {
    show: true,
    action: 'account_circle',
    title: 'Account',
    items: [
      {
        show: true,
        title: 'Logout',
        url: '/auth/logout',
      },
    ],
  },
];

export default config.filter((el) => {
  if (el.items) {
    el.items = el.items.filter((child) => {
      return child.show;
    });
  }

  return el.show;
});
